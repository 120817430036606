@import 'variables';

@mixin incorrect-answer-bg {
    background-color: $incorrect-answer-bg-color !important;
    color: $text-answer-bg-color-select !important;
}

@mixin correct-answer-bg {
    background-color: $correct-answer-bg-color !important;
    color: $text-answer-bg-color-select !important;
}