// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Tempus Dominus
@import '@eonasdan/tempus-dominus/src/scss/tempus-dominus';

// Jquery Smart Wizard
@import 'smartwizard/dist/css/smart_wizard_all.css';

// Variables
@import 'variables';

// Mixins
@import 'mixins';

// Modals
@import 'modals';

// Forms
@import 'forms';

// Tests
@import 'tests';

// Loader
.preloader {
    background-color: #1f2d3dcc;
}

.sidebar>.main_container {
    position: fixed;
}

.brand-text {
    overflow: hidden;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
    text-align: center;
}

.brand-link {
    display: flex;
    flex-direction: column;
}

.brand-link .brand-image {
    float: unset;
    align-self: center;
    height: 50px;
    max-height: unset;
    max-width: 90%;
    margin: 0;
    border-radius: 0%;
    transition: 0.5s;
    object-fit: cover;
}

.brand-link .brand-image.collapsed {
    border-radius: 50%;
}

[class*='sidebar-dark-'] .nav-treeview>.nav-item>.nav-link.active,
[class*='sidebar-dark-'] .nav-treeview>.nav-item>.nav-link.active:focus,
[class*='sidebar-dark-'] .nav-treeview>.nav-item>.nav-link.active:hover {
    color: #343a40 !important;
}

// BUG Notify inside modal
paper-toast {
    z-index: 1;
}

.remove-answer-btn {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
}

#pairs.info-row label>p {
    margin-bottom: 0;
}

.modal {
    overflow-y: auto;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    overflow-y: scroll;
    max-height: 12rem;
}

.audio-player {
    width: 20rem;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    max-width: 100%;
    overflow: hidden;
    word-wrap: normal !important;
    white-space: normal;
}

.set_height {
    height: 100% !important;
}

.rota-horizontal {
    transform: scaleX(-1);
}

.fit-cont {
    position: relative;
    height: 49px;
    padding: 0.5rem;
    border-radius: 16px;
    display: flex;
    align-items: center;
    box-shadow: 1px 1px 3px #DADADA;
}

.right-position-icon {
    position: absolute;
    right: -24px;
}

.fit-cont:has(svg.checked) {
    height: 49px;
    padding: 0.5rem;
    border-radius: 16px;
    display: flex;
    align-items: center;
    box-shadow: 1px 1px 3px #DADADA;
    color: white;
}

.review-option {
    cursor: default !important;
}

.pair-answer-icon-position {
    right: -24px;
    top: 50%;
    transform: translateY(-.5em);
}

.incorrect-bg-color {
    @include incorrect-answer-bg;
}

.correct-bg-color {
    @include correct-answer-bg;
}

.fit-cont:has(svg.checked.correct) {
    @include correct-answer-bg;
}

.fit-cont:has(svg.checked.incorrect) {
    @include incorrect-answer-bg;
}

.no-margins>* {
    margin-bottom: 0;
}