.flash {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 91;
}

#idPhotoCanvas {
    background-color: transparent;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 90;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

#webcam {
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: none;
    margin: auto;
}

#webcam-container {
    width: 305px;
    height: 230px;
    background-color: black;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
}

#webcam-controls {
    margin-top: 1rem;
}

.card-footer {
    gap: 1rem;
    font-weight: 700;

    .btn-test {
        background-color: #38476D;
        color: white;
    }

    .btn-save_send {
        background-color: #8ee383;
        color: white;
    }
}

.question-layout {
    padding: 1rem;
    background-color: white;
    border-radius: 1rem;
    flex-grow: 1;

    .question-type {
        font-weight: 600;
    }
}

.multiple-choice-answers {
    label {
        display: block;
        cursor: pointer;
        margin-bottom: .7rem;
        padding: 0 0.5rem;

        div {
            background-color: #fbfbfb;
            height: min-content;
            padding: 0.5rem;
            border-radius: 16px;
            display: flex;
            align-items: center;
            box-shadow: 1px 1px 3px #DADADA;
        }

        div:hover {
            transition: all;
            background-color: #f0f0f0;
        }

        input[type="radio"]:checked+div {
            background-color: rgb(26, 91, 181) !important;
            color: white;
        }

        span {
            border-right: 1px solid #cecece;
            font-size: 22px;
            padding: 0 1rem;
        }

        p {
            padding-left: 1rem;
            display: inline-block;
            margin-bottom: 0;
        }
    }
}

#testWebcamContainer {
    position: absolute;
    left: 0.25rem;
    bottom: 1rem;
    z-index: 9999;

    #testWebcam {
        border-radius: 16px;
        box-shadow: 0 0 10px rgba(0 0 0 / 30%);
    }
}

#testSidebar {
    overflow-y: scroll !important;
    overflow-y: overlay !important;
}

.pairs-answers {
    display: flex;
    justify-content: center;
    width: 100%;

    .pairs-item {
        background-color: #eaeaea;
        color: black;
        border-radius: .75rem;
        margin-bottom: .3rem;
        padding: .75rem 1.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        p {
            margin-bottom: 0;
        }
    }

    .pairs-item:last-child {
        margin-bottom: 0;
    }

    .pairs-item.active {
        background-color: #38476D;
        color: white;
        cursor: grab;
    }

    .pairs-item.active.sortable-chosen {
        cursor: grabbing;
    }
}

.main-sidebar {
    .sidebar {
        .os-content {
            .test-menu {
                flex-wrap: nowrap;
                flex-grow: 1;

                .test-sidebar-section {
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    /* number of lines to show */
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

.test-questions {
    flex-grow: 1;

    grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 8px;
        grid-row-gap: 8px;

        a {
            height: 35px;
            width: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #3e4f79;
            border: 1px solid white;
            color: white;
            border-radius: 100%;
            margin: auto auto;
            font-size: 15px;
        }

        a.completed {
            background-color: #8ee383;
            color: white;
        }

        a.active {
            background-color: #fff;
            color: #000;
        }
    }
}