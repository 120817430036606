.select2-search__field::placeholder {
    color: #999;
}

.file-output {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-top: 0.4rem !important;
}

.datepicker[readonly] {
    background-color: white;
}